import { Select, SelectProps } from "antd";
import { unique } from "../../Helpers/unique";

interface ValuesDropdownProps<T> extends Pick<SelectProps, "mode"> {
  value: T;
  setValue: (_: T) => void;
  values: string[];
  width?: number;
  placeholder?: any;
}

export function ValuesDropdown<T>(props: ValuesDropdownProps<T>) {
  const uniqueValues = unique(props.values)
    .filter((c) => c !== "")
    .filter((c) => c !== "-");
  return (
    <Select
      mode={props.mode}
      value={props.value}
      style={{ width: props.width ?? 220 }}
      onChange={function (e) {
        props.setValue(e);
      }}
      allowClear
      placeholder={props.placeholder}
    >
      {uniqueValues.map(function (value) {
        return (
          <Select.Option key={value} value={value}>
            {value}
          </Select.Option>
        );
      })}
    </Select>
  );
}
