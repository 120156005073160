import {
  CompanyData,
  CountryCode,
  CustomerInvoice,
  CustomerInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Switch } from "antd";
import { useState } from "react";
import { ExcelButton } from "./ExcelButton";
import dayjs from "dayjs";
import HorizontalStack from "./HorizontalStack";
import Spacer from "../Spacer";
import { getAllUniqueKeys } from "../Helpers/getAllUniqueKeys";

interface DownloadCustomerStatementButtonProps {
  customerInvoices: CustomerInvoice[];
  companyData: CompanyData;
}

interface DownloadCustomerStatementLinkProps
  extends DownloadCustomerStatementButtonProps {
  externalVersion: boolean;
  onOk: () => void;
}

function describeInvoiceState(state: CustomerInvoiceState) {
  switch (state) {
    case CustomerInvoiceState.SettlementPending:
      return "Payment Sent (Not yet received)";
    case CustomerInvoiceState.Settled:
      return "Payment Received";
    case CustomerInvoiceState.Voided:
      return "Voided ❌";
    case CustomerInvoiceState.Issued:
      return "Unpaid";
    case CustomerInvoiceState.Reconciled:
      return "Closed ✅";
  }
}

function backgroundColorForInvoiceState(state: CustomerInvoiceState): string {
  switch (state) {
    case CustomerInvoiceState.Settled:
      return "#E6FFE6"; // light green
    case CustomerInvoiceState.SettlementPending:
      return "#FFE6CC"; // light orange
    case CustomerInvoiceState.Issued:
      return "#FFFFD1"; // light yellow
    case CustomerInvoiceState.Voided:
      return "#CCCCCC"; // light red
    case CustomerInvoiceState.Reconciled:
      return "#C8E6C9"; // mid green
    default:
      return "#FFFFFF"; // white as fallback
  }
}

export function DownloadCustomerStatementLink(
  props: DownloadCustomerStatementLinkProps,
) {
  const internal = props.externalVersion === false;

  function getData() {
    if (props.customerInvoices.length === 0) {
      return [];
    }

    return props.customerInvoices
      .sort(function (a, b) {
        return dayjs(a.createdAt!).isBefore(dayjs(b.createdAt!)) ? -1 : 1;
      })
      .map(function (ci) {
        const o = {} as {
          [key: string]: string | undefined | CountryCode | number | Date;
        };

        o["Invoice Number"] = ci.invoiceIdentifier;
        o["Invoice Date"] = ci.createdAt ? new Date(ci.createdAt) : undefined;
        o["Invoice Due Date"] = ci.dueDate ? new Date(ci.dueDate) : undefined;
        o["Invoice Amount"] = ci.amount;
        o["Invoice Currency"] = ci.currency;
        o["Invoice State"] = ci.customerInvoiceState;
        o["Invoice State Description"] = describeInvoiceState(
          ci.customerInvoiceState!,
        );
        o["Disputed?"] = ci.disputed ? "Yes" : "No";
        o["Payment Method"] = ci.paymentMethod;
        o["Payment Method Brand"] = ci.paymentMethodBrand;
        o["Cardholder Name"] = ci.cardholderName;
        o["Payment Reference"] = ci.paymentReference;
        o["Settlement Pending Time"] = ci.settlementPendingTime
          ? new Date(ci.settlementPendingTime)
          : undefined;
        o["Payment Terms"] = `${ci.paymentTermsDays} days`;

        if (internal) {
          o["CustomerInvoiceId"] = ci.customerInvoiceId;
        }
        return o;
      });
  }

  function getFilename() {
    return `${props.companyData.company.companyName} Statement ${dayjs().format("YYYY-MM-DD")}.xlsx`;
  }

  const columnFormats = {};
  columnFormats[`Invoice Amount`] = {
    type: "currency",
    decimals: 2,
  };
  columnFormats["Invoice Date"] = {
    type: "date",
  };
  columnFormats["Invoice Due Date"] = {
    type: "date",
  };
  columnFormats["Settlement Pending Time"] = {
    type: "date",
  };

  function generateSheets() {
    const data = getData();
    const columns = getAllUniqueKeys(data);

    // Create detailed data sheet config
    const detailedSheet = {
      name: "Statement Lines",
      data,
      headers: columns,
      columnFormats,
      getRowStyle: (item: any, _index: number) => {
        const style: { color?: string; backgroundColor?: string } = {
          backgroundColor: backgroundColorForInvoiceState(
            item["Invoice State"],
          ),
        };

        return style;
      },
    };

    return [detailedSheet];
  }

  return (
    <ExcelButton
      generateSheets={generateSheets}
      getFilename={getFilename}
      autoWidth
      boldHeaders
      onOk={props.onOk}
    />
  );
}

export function DownloadCustomerStatementButton(
  props: DownloadCustomerStatementButtonProps,
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [externalVersion, setExternalVersion] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Company Statement Excel"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={700}
        footer={
          <HorizontalStack align="right" width="100%">
            <DownloadCustomerStatementLink
              {...props}
              externalVersion={externalVersion}
              onOk={handleOk}
            />
          </HorizontalStack>
        }
      >
        <Spacer height={16} />
        <Form
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="External Version"
            tooltip="Check this if you want to send this to a customer. It will remove sensitive internal only columns"
          >
            <Switch checked={externalVersion} onChange={setExternalVersion} />
          </Form.Item>
        </Form>
        <Spacer height={16} />
      </Modal>
      <Button
        icon={<DownloadOutlined />}
        onClick={showModal}
        type="link"
        style={{ margin: 0, fontSize: "12px" }}
      >
        Company Statement
      </Button>
    </>
  );
}
