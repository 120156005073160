import ExcelJS from "exceljs";
import { Button, message } from "antd";
import { downloadBlob } from "../Helpers/fileDownload";
import { DownloadOutlined } from "@ant-design/icons";
import Spacer from "../Spacer";

type Data = object[];

export type ColumnFormatType =
  | "percentage"
  | "currency"
  | "number"
  | "text"
  | "date";

export type ColumnFormat = {
  type: ColumnFormatType;
  decimals?: number;
  currencySymbol?: string;
};

// Add new type for row styling
type RowStyle = {
  color?: string;
  backgroundColor?: string;
};

export interface SheetConfig {
  name: string;
  data: Data;
  explanationRows?: string[];
  headers: string[];
  columnFormats?: Record<string, ColumnFormat>;
  getRowStyle?: (item: any, index: number) => RowStyle | undefined;
}

interface ExcelButtonProps {
  generateSheets: () => SheetConfig[];
  getFilename: () => string | undefined;
  boldHeaders?: boolean;
  autoWidth?: boolean;
  fontSize?: number;
  onOk: () => void;
}

export function ExcelButton(props: ExcelButtonProps) {
  const {
    generateSheets,
    getFilename,
    autoWidth = true,
    boldHeaders = true,
    fontSize = 16,
  } = props;

  const sheets = generateSheets();
  const downloadExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();

      // Create each sheet
      for (const sheetConfig of sheets) {
        const worksheet = workbook.addWorksheet(sheetConfig.name);

        // Set default font size for the worksheet
        worksheet.properties.defaultRowHeight = fontSize * 1.2;
        worksheet.properties.defaultColWidth = 12;

        // Add explanation rows
        if (sheetConfig.explanationRows) {
          sheetConfig.explanationRows.forEach((explanation) => {
            worksheet.addRow([explanation]);
          });
        }

        // Add headers
        worksheet.addRow(sheetConfig.headers);

        if (boldHeaders) {
          worksheet.getRow(1).font = { bold: true, size: fontSize };
        }

        // Set all columns to left alignment after adding headers
        worksheet.columns.forEach((column) => {
          column.alignment = { horizontal: "left" };
        });

        // Add data rows with font size
        sheetConfig.data.forEach((item, rowIndex) => {
          const row = worksheet.addRow(
            sheetConfig.headers.map((header) => item[header]),
          );
          row.font = { size: fontSize };

          // Apply row styling if provided
          const rowStyle = sheetConfig.getRowStyle?.(item, rowIndex);
          if (rowStyle) {
            const rowFont = { ...row.font }; // Preserve existing font properties

            // Apply text color if provided
            if (rowStyle.color) {
              rowFont.color = {
                argb: rowStyle.color.replace("#", ""),
              };
            }

            // Apply background color if provided
            if (rowStyle.backgroundColor) {
              row.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: rowStyle.backgroundColor.replace("#", "") },
              };
            }

            // Set the updated font properties
            row.font = rowFont;
          }
        });

        // Apply column formats
        if (sheetConfig.columnFormats) {
          Object.entries(sheetConfig.columnFormats).forEach(
            ([header, format]) => {
              const colIndex = sheetConfig.headers.indexOf(header) + 1;
              if (colIndex > 0) {
                const col = worksheet.getColumn(colIndex);
                if (format.type === "currency") {
                  col.numFmt = "$#,##0.00";
                } else if (format.type === "date") {
                  col.numFmt = "yyyy-mm-dd";
                } else if (format.type === "number") {
                  col.numFmt =
                    format.decimals === 0
                      ? "#"
                      : `#.${Array(format.decimals).fill("0").join("")}`;
                }
              }
            },
          );
        }

        // Improved auto-width calculation
        if (autoWidth) {
          worksheet.columns.forEach((column, index) => {
            const header = sheetConfig.headers[index];
            // Account for header length, considering it might be multiple words
            const headerLength = header
              ? header.length + (header.includes(" ") ? 2 : 0)
              : 0;

            const maxContentLength = sheetConfig.data.reduce((max, row) => {
              const value = row[header];
              // Handle numbers specifically to account for their formatting
              if (typeof value === "number") {
                return Math.max(max, value.toString().length + 2); // Add extra space for numbers
              }
              const stringValue = value?.toString() ?? "";
              return Math.max(max, stringValue.length);
            }, 0);

            const maxLength = Math.max(headerLength, maxContentLength);
            // Add 20% to the width plus more padding for headers
            column.width = maxLength * 1.2 + 6; // Increased padding from 4 to 6
          });
        }
      }

      const filename = getFilename();

      if (filename === undefined) {
        throw new Error("Filename is undefined");
      }

      // Generate and download the file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      downloadBlob(blob, filename);

      props.onOk();

      message.success("Report downloaded!");
    } catch (e) {
      console.error(`Error generating excel report`, { e });
      message.error("Problem generating report");
    }
  };

  function buttonTitle() {
    const filename = getFilename();
    if (filename) {
      return (
        <>
          Download
          <Spacer width={8} />
          <strong>{filename}</strong>
        </>
      );
    } else {
      return <>Download</>;
    }
  }

  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={downloadExcel}
      disabled={sheets.length === 0}
      type="primary"
    >
      {buttonTitle()}
    </Button>
  );
}
