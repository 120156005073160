import { ShipmentReport } from "@freightsimple/generated-apollo-openapi-client";
import { sleep } from "../../Helpers/sleep";
import Colors from "../../Components/Colors";

export function OpenShipmentsInNewTabsButton(props: {
  shipments: ShipmentReport[];
}) {
  async function openAllInNewTabs() {
    for (let i = 0; i < props.shipments.length; i++) {
      const url = `/view-shipment?shipmentId=${props.shipments[i].shipmentId}`;
      window.open(url);
      await sleep(300);
    }
  }

  return (
    <span
      onClick={openAllInNewTabs}
      style={{
        color: Colors.Blue,
        cursor: "pointer",
        fontSize: "11px",
        fontWeight: 600,
      }}
    >
      Open All in New Tabs
    </span>
  );
}
