import { Select } from "antd";
import LocationFilterData from "../../GeneratedFixtures/LocationFilters.json";
import { groupBy } from "../../Helpers/groupBy";

const { Option, OptGroup } = Select;

interface LocationDropdownProps {
  locationFilterIdentifiers: string[] | undefined | null;
  setLocationFilterIdentifiers: (_: string[] | undefined) => void;
}

export function LocationDropdown(props: LocationDropdownProps) {
  const filterGroups = groupBy(LocationFilterData, (f) => f.groupName);
  return (
    <Select
      allowClear={true}
      showSearch
      mode="multiple"
      style={{ width: 220 }}
      value={props.locationFilterIdentifiers}
      placeholder="Filter Type"
      optionFilterProp="children"
      onSelect={function (_filterGroupType: string) {
        props.setLocationFilterIdentifiers([
          ...(props.locationFilterIdentifiers || []),
          _filterGroupType,
        ]);
      }}
      onDeselect={function (value: string) {
        props.setLocationFilterIdentifiers(
          props.locationFilterIdentifiers?.filter((item) => item !== value),
        );
      }}
      onClear={function () {
        props.setLocationFilterIdentifiers(undefined);
      }}
      filterOption={function (input, option) {
        // @ts-expect-error - Might be null
        return option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      filterSort={function (optionA, optionB) {
        return (
          optionA?.value
            // @ts-expect-error - Might be null
            ?.toLowerCase()
            // @ts-expect-error - Might be null
            ?.localeCompare(optionB?.value.toLowerCase())
        );
      }}
    >
      {filterGroups.map(function (filterGroup) {
        const groupName = filterGroup.key;
        return (
          <OptGroup label={groupName} key={groupName}>
            {filterGroup.value.map(function (filter) {
              const value = `${filter.groupName}/${filter.description}`;
              return (
                <Option value={value} key={value}>
                  <div>{filter.description}</div>
                </Option>
              );
            })}
          </OptGroup>
        );
      })}
    </Select>
  );
}
