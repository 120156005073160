import {
  CompanyShipmentQuote,
  ShipmentReport,
  ShipmentReportInvoiceWarningState,
} from "@freightsimple/generated-apollo-openapi-client";

import { Form, message, Tag } from "antd";
import { useEffect, useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import { Loading } from "../../Components/Loading";
import { Percentage } from "../../Components/Percentage";
import { ShipmentStatusTag } from "../../Components/ShipmentStatusTag";
import Stack from "../../Components/Stack";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import { Volume } from "../../Components/Volume";
import { Weight } from "../../Components/Weight";
import { calculatePercentageOfTrailer } from "../../Helpers/calculatePercentageOfTrailer";
import { isNotBlank } from "@freightsimple/shared";
import { stringToColor } from "../../Helpers/stringToColor";
import Spacer from "../../Spacer";
import { InvoiceAuditProblemComponent } from "../OpenInvoiceAuditsScreen";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { MoreDimensionsInfo, QuoteSummaryComponent } from "../ViewQuotesScreen";
import dayjs from "dayjs";
import { Search } from "../../Types/AntHelperTypes";

interface ShipmentReportsTableProps {
  data: Array<ShipmentReport>;
  bookedShipments: Array<CompanyShipmentQuote>;
}

function ShipmentReportsTable(props: ShipmentReportsTableProps) {
  function lookupBookedShipment(sr: ShipmentReport): CompanyShipmentQuote {
    return props.bookedShipments.find(
      (bs) => bs.shipment.shipmentId === sr.shipmentId,
    )!;
  }

  const [columns] = useState<DataTableColumn<ShipmentReport>[]>([
    {
      key: "company",
      show: true,
      title: "Company",
      render: (o) => (
        <Stack
          align="left"
          style={{
            borderLeft: `8px solid ${stringToColor(o.shipmentHash!)}`,
            paddingLeft: "16px",
          }}
        >
          <div style={{ fontWeight: 500 }}>
            <HorizontalStack>
              {o.companyName}
              <Spacer width={4} />
              <span>
                {o.insuranceRequested ? (
                  <Tag color="purple">Insurance</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.deletedDescription ? (
                  <Tag color="red">Deleted : {o.deletedDescription}</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.tags?.map((s) => (
                  <Tag key={s} color="orange">
                    {s}
                  </Tag>
                ))}
                {o.problems?.map((s) => (
                  <Tag key={s} color="magenta">
                    🚩 {s}
                  </Tag>
                ))}
              </span>
              <span>
                {o.manualQuotingRequested ? (
                  <Tag color="blue">🦸‍♀️ Manual Quoting Requested</Tag>
                ) : (
                  ""
                )}
              </span>
              <span>
                {o.invoiceWarningState !==
                ShipmentReportInvoiceWarningState.NoWarning ? (
                  <Tag color="red">{o.invoiceWarningState}</Tag>
                ) : (
                  ""
                )}
              </span>
            </HorizontalStack>
          </div>
          <div>
            {o.pickupCityState} to {o.deliveryCityState}{" "}
          </div>
          <Spacer height={4} />
          <ShipmentStatusTag status={o.state} />
          <Spacer height={16} />
          <HorizontalStack style={{ fontSize: "12px" }}>
            <div>Units:</div>
            <Spacer width={2} />
            <div>{o.totalHandlingUnits!}</div>
            <Spacer width={8} />
            <div>Weight:</div>
            <Spacer width={2} />
            <Weight>{o.totalWeight!}</Weight>
            <Spacer width={8} />
            <div>Volume:</div>
            <Spacer width={2} />
            <Volume>{o.totalVolume!}</Volume>
            <Spacer width={8} />
            <div>Trailer:</div>
            <Percentage>
              {calculatePercentageOfTrailer(o.lineItems!)}
            </Percentage>
            <MoreDimensionsInfo shipmentReport={o} />
          </HorizontalStack>
          <div>{o.lineItems![0].description}</div>
          <Spacer height={8} />
          <div style={{ fontSize: "12px", color: Colors.LightText }}>
            <CreatedAt timestamp={o.quotedAt} />
          </div>
        </Stack>
      ),
      sorter: function (a, b) {
        return dayjs(a.quotedAt).valueOf() - dayjs(b.quotedAt).valueOf();
      },
    },
    {
      key: "quotes",
      show: true,
      title: "Booked Quote",
      render: (o) => (
        <HorizontalStack spacing={16} style={{ marginLeft: "-16px" }}>
          {o.quotes!.length === 0 && <div>No quotes</div>}
          {o
            .quotes!.filter((q) => q.booked)
            .map(function (q) {
              return <QuoteSummaryComponent key={q.quoteId!} quote={q} />;
            })}
        </HorizontalStack>
      ),
    },
    {
      key: "problems",
      show: true,
      title: "Problems",
      render: (o) => (
        <Stack align="left">
          {lookupBookedShipment(o).shipment.invoiceAuditProblems!.map(
            (p, index) => (
              <InvoiceAuditProblemComponent key={index} problem={p} />
            ),
          )}
        </Stack>
      ),
    },
    {
      key: "action",
      show: true,
      title: "Action",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!} />
        </ButtonRow>
      ),
    },
  ]);

  return (
    <Stack align="left">
      <DataTable pagination={false} columns={columns} data={props.data} />
    </Stack>
  );
}

export function CompanyInvoiceProblemsTab(props: CompanyTabProps) {
  const createCompaniesApi = useCompaniesApi();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<ShipmentReport[]>();
  const [bookedShipments, setBookedShipments] =
    useState<CompanyShipmentQuote[]>();

  const companyId = props.data.company.companyId!;
  const [filter, setFilter] = useState<string>();

  async function refresh() {
    setLoading(true);

    try {
      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.getShipmentReportsForCompany({
        companyId,
      });
      const response2 = await companiesApi.getBookedShipmentsForCompany({
        companyId,
      });

      const filteredReports = response.filter((sr) => {
        const bookedShipment = response2.find(
          (bs) => bs.shipment.shipmentId === sr.shipmentId
        );
        
        return (
          bookedShipment?.shipment?.invoiceAuditProblems &&
          bookedShipment.shipment.invoiceAuditProblems.length > 0
        );
    });
      setReports(filteredReports);
      setBookedShipments(response2);
    } catch (e) {
      message.error(`Something went wrong : ${e}`);
    }
    setLoading(false);
  }

  useEffect(function () {
    refresh();
  }, []);

  if (loading || reports === undefined || bookedShipments === undefined) {
    return <Loading />;
  }

  function filterReports(reports: ShipmentReport[]): ShipmentReport[] {
    function applyGeneralFilter(r: ShipmentReport): boolean {
      if (filter && isNotBlank(filter)) {
        return JSON.stringify(r).includes(filter);
      } else {
        return true;
      }
    }

    return reports.filter(applyGeneralFilter);
  }

  return (
    <Stack align="left">
      <HorizontalStack align="spread" verticalAlign="middle">
        <HorizontalStack style={{ marginRight: "30px" }}>
          <Form.Item label="Filter" style={{ marginBottom: "30px" }}>
            <Search
              value={filter}
              placeholder="Filter by anything"
              allowClear
              onChange={function (e) {
                setFilter(e.target.value);
              }}
            />
          </Form.Item>
        </HorizontalStack>
      </HorizontalStack>

      <ShipmentReportsTable
        data={filterReports(reports)}
        bookedShipments={bookedShipments}
      />
    </Stack>
  );
}
