import locationTypes from "../GeneratedFixtures/LocationTypes.json";
import distributionWarehouseBrands from "../GeneratedFixtures/DistributionWarehouseBrands.json";

export function nameLocationType(
  locationType: string,
  distributionWarehouseBrand: string | undefined,
): string {
  const locationTypeName = locationTypes.find(
    (l) => l.type === locationType,
  )!.name;
  if (locationType === "DistributionWarehouse") {
    const distributionWarehouseBrandName = distributionWarehouseBrands.find(
      (d) => d.identifier === distributionWarehouseBrand,
    )?.displayName;
    if (distributionWarehouseBrandName) {
      return `${distributionWarehouseBrandName} ${locationTypeName}`;
    }
  }

  return locationTypeName;
}

export function describeLocationType(locationType: string): string {
  return locationTypes.find((l) => l.type === locationType)!.description;
}

export function getLocationTypes() {
  return locationTypes.map((l) => l.type);
}
