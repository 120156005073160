import dayjs from "dayjs";

interface CreatedAtProps {
  timestamp: string | undefined | null;
  days?: boolean;
  skipDelta?: boolean;
}

export function CreatedAt(props: CreatedAtProps) {
  if (!props.timestamp) {
    return <div>-</div>;
  }

  const d = dayjs.utc(props.timestamp).tz("America/Vancouver");
  const delta = d.fromNow();
  const formatted = props.days
    ? d.format("MMM D YYYY")
    : d.format("dddd, MMMM D YYYY, h:mm:ss A z");

  if (props.skipDelta) {
    return <div>{formatted}</div>;
  }

  return (
    <div>
      {formatted} ({delta})
    </div>
  );
}
