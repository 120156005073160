import { Typography } from "antd";
import styled from "styled-components";
import { StatsCalendar2 } from "./StatsCalendar2";
import { TimeGraph } from "./TimeGraph";
import Spacer from "../Spacer";
import Colors from "./Colors";
import {
  CalendarDataResponse,
  TimeGraphData,
} from "@freightsimple/generated-apollo-openapi-client";

const { Title } = Typography;

const Subtext = styled.div`
  font-size: 12px;
  color: ${Colors.LightText};
`;

interface KpiSectionProps {
  title: string;
  data: TimeGraphData | CalendarDataResponse;
  maxValue?: number;
  subtitle?: string;
  useTimeGraph?: boolean;
}

export function KpiSection(props: KpiSectionProps) {
  return (
    <>
      <Title level={4}>{props.title}</Title>
      {props.subtitle && <Subtext>{props.subtitle}</Subtext>}
      {props.useTimeGraph ? (
        <TimeGraph data={props.data as TimeGraphData} />
      ) : (
        <StatsCalendar2
          data={props.data as CalendarDataResponse}
          maxValue={props.maxValue ?? 0}
        />
      )}
      <Spacer height={64} />
    </>
  );
}
