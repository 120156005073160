import { ApolloMapCompany } from "@freightsimple/generated-apollo-openapi-client";
import { Select, Spin } from "antd";
import { useState } from "react";
import { useMapsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { useOnce } from "../../Hooks/useOnce";

interface CompaniesDropdownProps {
  companyId: string | undefined | null;
  setCompanyId: (_: string | undefined) => void;
  disabled?: boolean;
}

export function CompaniesDropdown(props: CompaniesDropdownProps) {
  const createMapsApi = useMapsApi();
  const [companies, setCompanies] = useState<ApolloMapCompany[] | undefined>();

  useOnce(async function () {
    const mapsApi = await createMapsApi();
    const response = await mapsApi.getCompanies();
    setCompanies(response);
  });

  if (companies === undefined) {
    return <Spin size="small" />;
  }

  return (
    <Select
      allowClear={true}
      showSearch
      style={{ width: 300 }}
      value={props.companyId}
      placeholder="Company"
      optionFilterProp="children"
      onSelect={function (_companyId: string) {
        props.setCompanyId(_companyId);
      }}
      onClear={function () {
        props.setCompanyId(undefined);
      }}
      filterOption={function (input, option) {
        if (option === undefined) {
          return false;
        }
        return option.companyName.toLowerCase().includes(input.toLowerCase());
      }}
      filterSort={(optionA, optionB) =>
        optionA.companyName.localeCompare(optionB.companyName)
      }
      options={companies}
      optionRender={(option) => (
        <HorizontalStack verticalAlign="middle" align="left">
          <div>{option.data.companyName}</div>
        </HorizontalStack>
      )}
    />
  );
}
