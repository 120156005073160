import { useSearchParams } from "react-router-dom";

export type UseParamStateReturn<T> = [T, (value: T) => void, string | null];

export interface UseParamStateConfig<T> {
  key: string;
  defaultValue: T;
  removeIf?: (value: T) => boolean;
}

/**
 * A custom hook for synchronizing state with URL query parameters using `URLSearchParams`.
 * It provides a way to read from and write to the URL query string, making it useful for persisting state across page reloads and URL changes.
 *
 * @param config - Configuration object for managing the state:
 *   - `key`: The query parameter key in the URL.
 *   - `defaultValue`: The default value to be used when the key is not present or when the value cannot be parsed.
 *   - `removeIf`: An optional function that determines whether the query parameter should be removed from the URL based on the state value.
 *
 * @returns A tuple containing:
 *   - `state`: The current state value, either from the URL query parameter or the `defaultValue` if the parameter is absent or invalid.
 *   - `setState`: A function to update the URL query parameter. It serializes the value before updating or deletes the parameter if necessary.
 *   - `serializedValue`: The raw string value of the URL parameter (`string | null`). If the key is absent or invalid, this will be `null`.
 *
 * This hook is especially useful for managing query parameters in filters, pagination, or search states, allowing for state persistence across navigation or page reloads.
 *
 * Example usage:
 * ```tsx
 * const [hideUninterestingCompanies, setHideUninterestingCompanies] = useParamState({
 *    key: "hideUninterestingCompanies",
 *    defaultValue: true,
 *  });
 * ```
 */
export function useParamState<T>({
  key,
  defaultValue,
  removeIf,
}: UseParamStateConfig<T>): UseParamStateReturn<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const setValue = (newValue: T) => {
    const serializedValue = JSON.stringify(newValue);
    const serializedDefaultValue = JSON.stringify(defaultValue);
    const updatedParams = new URLSearchParams(searchParams);

    // If the value should be removed or is equal to the default value, delete the parameter
    if (removeIf?.(newValue) || serializedValue === serializedDefaultValue) {
      updatedParams.delete(key);
    } else {
      updatedParams.set(key, serializedValue);
    }

    setSearchParams(updatedParams);
  };

  const storedValue = searchParams.get(key);

  if (storedValue === null) {
    return [defaultValue, setValue, null];
  }

  try {
    const parsedValue = JSON.parse(storedValue) as T;
    return [parsedValue, setValue, storedValue];
  } catch (error) {
    console.error(`Error parsing value for key "${key}":`, error);
    return [defaultValue, setValue, storedValue];
  }
}
