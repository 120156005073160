import {
  InvoiceAuditProblem,
  InvoiceAuditProblemType,
} from "@freightsimple/generated-apollo-openapi-client";
import InvoiceAuditProblemTypes from "../../GeneratedFixtures/InvoiceAuditProblemTypes.json";
import { isNotBlank } from "@freightsimple/shared";
import { nameLocationType } from "../../Helpers/locationTypes";

export function describeInvoiceAuditProblemType(
  iap: InvoiceAuditProblem,
): string {
  try {
    const type = iap.type!;
    const description = InvoiceAuditProblemTypes.find(
      (o) => o.type === type,
    )!.name;
    if (
      isNotBlank(iap.correctedLocationType) &&
      (type === InvoiceAuditProblemType.PickupLocationTypeChange ||
        type === InvoiceAuditProblemType.DeliveryLocationTypeChange)
    ) {
      return `${description} (${nameLocationType(iap.correctedLocationType!, undefined)})`;
    } else {
      return description;
    }
  } catch {
    console.trace(`describeInvoiceAuditProblemType`, { iap });
    return `Problem generating description for ${JSON.stringify(iap)}`;
  }
}
