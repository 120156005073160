import {
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
} from "@freightsimple/generated-apollo-openapi-client";
import { Button, Modal, message } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import { TabProps } from "./TabProps";
import { useDisplay } from "../../Hooks/useDisplay";

interface PartialCustomerRefundButtonProps extends TabProps {
  customerInvoice: CustomerInvoice;
}

export function PartialCustomerRefundButton(
  props: PartialCustomerRefundButtonProps,
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!;
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const display = useDisplay();

  async function handleOk() {
    setIsLoading(true);
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      await customerInvoiceApi.reduceOrRefundPartialInvoice({
        customerInvoiceId,
        amount,
      });
      await props.onRefresh();
      message.success(`Refund processed`);
      display.hide();
      setAmount(0);
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
    setIsLoading(false);
  }

  const enabled =
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Settled ||
    props.customerInvoice.customerInvoiceState ===
      CustomerInvoiceState.Reconciled;

  const isRefundable =
    props.customerInvoice.invoiceType === CustomerInvoiceType.ShipmentCharge ||
    props.customerInvoice.invoiceType === CustomerInvoiceType.AdditionalCharge;

  if (!enabled || !isRefundable) {
    return <></>;
  }

  return (
    <>
      <Modal
        title="Partial Refund Customer Invoice"
        open={display.status}
        onOk={handleOk}
        onCancel={display.hide}
        width={400}
        confirmLoading={isLoading}
        destroyOnClose
      >
        <p>How much do you want to refund the customer for this invoice?</p>
        <CurrencyInput
          amount={amount}
          setAmount={setAmount}
          currency={props.shipmentData.shipment.company.currency!}
        />
      </Modal>
      <Button onClick={display.show}>Partial Refund</Button>
    </>
  );
}
