import {
  CarrierCreditNote,
  CarrierInvoice,
  CarrierInvoiceData,
  CarrierInvoiceState,
  CompanyShipmentCarrierInvoice,
  CompanyShipmentQuote,
  CurrencyCode,
  ResponseError,
} from "@freightsimple/generated-apollo-openapi-client";

import {
  Alert,
  Button,
  Input,
  message,
  Popover,
  Statistic,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCarrierInvoiceApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatsRow } from "../Components/StatsRow";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { assertNever } from "../Helpers/assertNever";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import { sum } from "../Helpers/sum";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";
import dayjs from "dayjs";
import { WarningComponent } from "../Components/WarningComponent";
import HorizontalStack from "../Components/HorizontalStack";
import { DownloadCarrierStatementButton } from "../Components/DownloadCarrierStatementLink";
import Colors from "../Components/Colors";

const { TabPane } = Tabs;

interface CheckIfInvoicesArePaidButtonProps {
  onRefresh: () => Promise<void>;
}

function CheckIfInvoicesArePaidButton(
  props: CheckIfInvoicesArePaidButtonProps,
) {
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    const api = await createCarrierInvoiceApi();
    await api.checkIfInvoicesArePaid();
    message.success("Done!");
    await props.onRefresh();
    setLoading(false);
  }

  return (
    <Button onClick={onClick} loading={loading}>
      Update from Xero
    </Button>
  );
}

interface ShipmentsTableProps {
  shipments: CompanyShipmentQuote[];
}

function ShipmentsTable(props: ShipmentsTableProps) {
  const columns: DataTableColumn<CompanyShipmentQuote>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.quote.carrierIdentifier!}
          brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.shipment.proNumber}</div>,
    },
    {
      title: "Quoted Price",
      render: (o) => (
        <div>{formatAsCurrency(o.quote.quotedPriceByCarrierCad, "CAD")}</div>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipment.shipmentId!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <Stack>
      <DataTable pagination={false} columns={columns} data={props.shipments} />
    </Stack>
  );
}

interface CarrierInvoicesTableProps {
  carrierInvoices: CompanyShipmentCarrierInvoice[];
  includePaymentPendingDate?: boolean;
}

export function CarrierInvoicesTable(props: CarrierInvoicesTableProps) {
  const total = sum(props.carrierInvoices, (ci) => ci.carrierInvoice.amount!);

  const columns: DataTableColumn<CompanyShipmentCarrierInvoice>[] = [];
  columns.push({
    title: "Carrier",
    render: (o) => (
      <Stack align="left">
        <CarrierLogo
          carrierIdentifier={o.carrierInvoice.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
        {o.shipment.invoiceAuditOpen && (
          <Tag color="red">🚩 Invoice Audit Open</Tag>
        )}
      </Stack>
    ),
  });
  columns.push({
    title: "Pro Number",
    render: (o) => <div>{o.carrierInvoice.invoiceIdentifier}</div>,
  });
  columns.push({
    title: "Pickup Date",
    render: (o) => (
      <div>{dayjs(o.shipment.pickupDate).format("MMM Do YYYY")}</div>
    ),
    sorter: function (a, b) {
      return (
        dayjs(a.shipment.pickupDate).valueOf() -
        dayjs(b.shipment.pickupDate).valueOf()
      );
    },
  });
  columns.push({
    title: "Delivery Date",
    render: (o) => (
      <div>{dayjs(o.shipment.actualDeliveryDate).format("MMM Do YYYY")}</div>
    ),
    sorter: function (a, b) {
      return (
        dayjs(a.shipment.actualDeliveryDate).valueOf() -
        dayjs(b.shipment.actualDeliveryDate).valueOf()
      );
    },
  });
  columns.push({
    title: "Amount",
    render: (o) => (
      <div>
        ${o.carrierInvoice.amount} {o.carrierInvoice.currency}
      </div>
    ),
  });
  if (props.includePaymentPendingDate) {
    columns.push({
      title: "Payment Pending Date",
      render: (o) => (
        <div>
          {dayjs(o.carrierInvoice.paymentPendingTime).format("MMM Do YYYY")}
        </div>
      ),
      sorter: function (a, b) {
        return (
          dayjs(a.carrierInvoice.paymentPendingTime).valueOf() -
          dayjs(b.carrierInvoice.paymentPendingTime).valueOf()
        );
      },
    });
  }
  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow>
        <ViewShipmentButton shipmentId={o.carrierInvoice.shipmentId!} />
      </ButtonRow>
    ),
  });

  return (
    <Stack align="left">
      <div>Total: ${total.toLocaleString()}</div>
      <Spacer height={16} />
      <DataTable
        pagination={false}
        columns={columns}
        data={props.carrierInvoices}
      />
    </Stack>
  );
}

interface CarrierCreditNotesTableProps {
  carrierCreditNotes: CarrierCreditNote[];
}

export function CarrierCreditNotesTable(props: CarrierCreditNotesTableProps) {
  const columns: DataTableColumn<CarrierInvoice>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.invoiceIdentifier}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <div>
          ${o.amount} {o.currency}
        </div>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.carrierCreditNotes}
    />
  );
}

const CarrierInvoicesCurrencyDropdown = OptionalEnumDropdown<
  CurrencyCode,
  typeof CurrencyCode
>(CurrencyCode, "Currency", function (et) {
  switch (et) {
    case CurrencyCode.Cad:
      return "🇨🇦 CAD ";
    case CurrencyCode.Usd:
      return "🇺🇸 USD";
    default:
      assertNever(et);
  }
});

export function CarrierInvoicesScreen() {
  const [carrierInvoices, setCarrierInvoices] = useState<
    CarrierInvoiceData | undefined
  >();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >();

  const [currencyFilter, setCurrencyFilter] = useState<CurrencyCode>();
  const [searchChequeNumber, setSearchChequeNumber] = useState("");
  const [searchChequeNumberLoading, setSearchChequeNumberLoading] =
    useState(false);

  async function refresh() {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const response = await carrierInvoiceApi.viewCarrierInvoices();
    setCarrierInvoices(response);
  }

  const query = useQuery();
  const navigate = useNavigate();

  const tab = query.tab as string;

  useOnce(refresh);

  function callback(key: string) {
    navigate(`/carrier-invoices?tab=${key}`, {
      replace: true,
    });
  }

  if (carrierInvoices === undefined) {
    return <Loading />;
  }

  const totalShipmentsWithoutInvoices = sum(
    carrierInvoices.shipmentsWithoutInvoices,
    function (s) {
      return s.quote.quotedPriceByCarrierCad!;
    },
  );

  function invoicePriceInCad(i: CompanyShipmentCarrierInvoice) {
    if (i.carrierInvoice.currency === CurrencyCode.Cad)
      return i.carrierInvoice.amount!;
    else return i.carrierInvoice.amount! * 1.32;
  }

  const totalIssuedInvoices = sum(
    carrierInvoices.nonVoidedCarrierInvoices.filter(
      (ci) => ci.carrierInvoice.invoiceState === CarrierInvoiceState.Issued,
    ),
    invoicePriceInCad,
  );
  const totalApprovedInvoices = sum(
    carrierInvoices.nonVoidedCarrierInvoices.filter(
      (ci) => ci.carrierInvoice.invoiceState === CarrierInvoiceState.Approved,
    ),
    invoicePriceInCad,
  );
  const totalPaymentPendingInvoices = sum(
    carrierInvoices.nonVoidedCarrierInvoices.filter(
      (ci) =>
        ci.carrierInvoice.invoiceState === CarrierInvoiceState.PaymentPending,
    ),
    invoicePriceInCad,
  );

  function applyCarrierIdentifierFilter(
    invoice: CompanyShipmentCarrierInvoice,
  ) {
    if (carrierIdentifier === undefined) {
      return true;
    } else {
      return carrierIdentifier === invoice.carrierInvoice.carrierIdentifier;
    }
  }

  function applyCarrierIdentifierFilterToCSQ(shipment: CompanyShipmentQuote) {
    if (carrierIdentifier === undefined) {
      return true;
    } else {
      return carrierIdentifier === shipment.quote.carrierIdentifier;
    }
  }

  function applyCurrencyFilter(
    invoice: CompanyShipmentCarrierInvoice,
  ): boolean {
    if (currencyFilter === undefined) {
      return true;
    }

    return invoice.carrierInvoice.currency === currencyFilter;
  }

  function applyFilters(invoices: CompanyShipmentCarrierInvoice[]) {
    return invoices
      .filter(applyCarrierIdentifierFilter)
      .filter(applyCurrencyFilter);
  }

  function applyShipmentsFilters(shipments: CompanyShipmentQuote[]) {
    return shipments.filter(applyCarrierIdentifierFilterToCSQ);
  }

  async function handleSearchByChequeNumber() {
    setSearchChequeNumberLoading(true);
    try {
      const carrierInvoiceApi = await createCarrierInvoiceApi();
      const invoice = await carrierInvoiceApi.getCarrierInvoiceByChequeNumber({
        chequeNumber: searchChequeNumber,
      });
      if (invoice.shipmentId) {
        navigate(
          `/view-shipment?shipmentId=${invoice.shipmentId}&tab=carrierinvoices`,
        );
      } else {
        message.error("Something went wrong!");
        console.error(invoice);
      }
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 404) {
        message.warning(
          "No carrier invoice found for the entered cheque number.",
        );
      } else {
        message.error("Something went wrong!");
        console.error(e);
      }
    }
    setSearchChequeNumberLoading(false);
  }

  const paymentPendingInvoicesMoreThan30Days =
    carrierInvoices.nonVoidedCarrierInvoices
      .filter(
        (ci) =>
          ci.carrierInvoice.invoiceState === CarrierInvoiceState.PaymentPending,
      )
      .filter(function (ci) {
        return dayjs(ci.carrierInvoice.paymentPendingTime!).isBefore(
          dayjs().subtract(30, "days"),
        );
      });

  const filteredIssuedInvoices = applyFilters(
    carrierInvoices.nonVoidedCarrierInvoices.filter(
      (ci) => ci.carrierInvoice.invoiceState === CarrierInvoiceState.Issued,
    ),
  );

  const filteredApprovedInvoices = applyFilters(
    carrierInvoices.nonVoidedCarrierInvoices.filter(
      (ci) => ci.carrierInvoice.invoiceState === CarrierInvoiceState.Approved,
    ),
  );

  const filteredPaymentPendingInvoices = applyFilters(
    carrierInvoices.nonVoidedCarrierInvoices.filter(
      (ci) =>
        ci.carrierInvoice.invoiceState === CarrierInvoiceState.PaymentPending,
    ),
  );

  const filteredShipmentsWithoutInvoices = applyShipmentsFilters(
    carrierInvoices.shipmentsWithoutInvoices,
  );

  const filteredShipmentsOverdueAnInvoice = applyShipmentsFilters(
    carrierInvoices.shipmentsOverdueAnInvoice,
  );

  return (
    <>
      <Page
        title={`Carrier Invoices`}
        tags={[]}
        stats={
          <HorizontalStack align="spread" width="100%">
            <StatsRow>
              <Statistic
                title="Missing Invoices"
                value={formatAsCurrency(totalShipmentsWithoutInvoices)}
              />
              <Statistic
                title="Issued Invoices"
                value={formatAsCurrency(totalIssuedInvoices)}
              />
              <Statistic
                title="Approved Invoices"
                value={formatAsCurrency(totalApprovedInvoices)}
              />
              <Statistic
                title="Payment Pending Invoices"
                value={formatAsCurrency(totalPaymentPendingInvoices)}
              />
            </StatsRow>
            <ButtonRow>
              <Popover
                content={
                  <Stack align="left" style={{ marginLeft: "-16px" }}>
                    <DownloadCarrierStatementButton
                      carrierInvoices={carrierInvoices.nonVoidedCarrierInvoices}
                    />
                  </Stack>
                }
                title="Available Reports"
                trigger="hover"
                placement="left"
              >
                <span
                  style={{
                    color: Colors.Blue,
                    cursor: "pointer",
                    fontSize: "11px",
                    fontWeight: 600,
                  }}
                >
                  Available Reports
                </span>
              </Popover>
            </ButtonRow>
          </HorizontalStack>
        }
        extra={[
          <>
            <CheckIfInvoicesArePaidButton onRefresh={refresh} />
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              setCarrierIdentifier={setCarrierIdentifier}
            />
            <CarrierInvoicesCurrencyDropdown
              value={currencyFilter}
              setValue={setCurrencyFilter}
            />
            <Popover
              title="Search by Cheque Number"
              trigger="click"
              placement="bottom"
              content={
                <div style={{ textAlign: "end" }}>
                  <Input
                    value={searchChequeNumber}
                    onChange={(e) => setSearchChequeNumber(e.target.value)}
                    placeholder="Cheque number"
                  />
                  <Spacer height={8} />
                  <Button
                    type="primary"
                    onClick={handleSearchByChequeNumber}
                    loading={searchChequeNumberLoading}
                  >
                    Search
                  </Button>
                </div>
              }
            >
              <Tooltip
                placement="left"
                title="Search for a carrier invoice using the provided cheque number and navigate to its associated shipment details."
              >
                <Button>Search by Cheque Number</Button>
              </Tooltip>
            </Popover>
          </>,
        ]}
      >
        <PageTitle>{`Carrier Invoices`}</PageTitle>
        <Tabs
          defaultActiveKey={tab}
          onChange={callback}
          style={{ width: "100%" }}
        >
          {filteredShipmentsWithoutInvoices.length > 0 && (
            <TabPane
              tab={`Shipments without Invoices  (${filteredShipmentsWithoutInvoices.length})`}
              key="shipmentsWithoutInvoices"
            >
              <ShipmentsTable shipments={filteredShipmentsWithoutInvoices} />
            </TabPane>
          )}
          {filteredShipmentsOverdueAnInvoice.length > 0 && (
            <TabPane
              tab={
                <>
                  Shipments Overdue an Invoice (<WarningComponent />
                  {filteredShipmentsOverdueAnInvoice.length})
                </>
              }
              key="shipmentsOverdueAnInvoice"
            >
              <ShipmentsTable shipments={filteredShipmentsOverdueAnInvoice} />
            </TabPane>
          )}
          <TabPane
            tab={`Issued Invoices  (${filteredIssuedInvoices.length})`}
            key="issuedInvoices"
          >
            <CarrierInvoicesTable carrierInvoices={filteredIssuedInvoices} />
          </TabPane>
          <TabPane
            tab={`Approved Invoices (${filteredApprovedInvoices.length})`}
            key="approvedInvoices"
          >
            <CarrierInvoicesTable carrierInvoices={filteredApprovedInvoices} />
          </TabPane>
          <TabPane
            tab={`Ready To Pay Invoices (${
              applyFilters(carrierInvoices.invoicesReadyToPay).length
            })`}
            key="readyToPayInvoices"
          >
            <Alert
              type="info"
              description="These are the invoices that are considered ready to pay. This should match the invoices with Payment tasks in Monday"
            />
            <Spacer height={8} />
            <CarrierInvoicesTable
              carrierInvoices={applyFilters(carrierInvoices.invoicesReadyToPay)}
            />
          </TabPane>
          <TabPane
            tab={`Payment Pending Invoices  (${
              filteredPaymentPendingInvoices.length
            })`}
            key="paymentPendingInvoices"
          >
            <CarrierInvoicesTable
              carrierInvoices={filteredPaymentPendingInvoices}
              includePaymentPendingDate
            />
          </TabPane>
          <TabPane
            tab={`Payment Pending > 30 days  (${
              applyFilters(paymentPendingInvoicesMoreThan30Days).length
            })`}
            key="paymentPendingInvoicesMoreThan30Days"
          >
            <CarrierInvoicesTable
              carrierInvoices={applyFilters(
                paymentPendingInvoicesMoreThan30Days,
              )}
              includePaymentPendingDate
            />
          </TabPane>
        </Tabs>
      </Page>
    </>
  );
}
