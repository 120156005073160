import {
  CompanyData,
  CustomerInvoice,
  CustomerInvoiceState,
} from "@freightsimple/generated-apollo-openapi-client";
import { Form, InputNumber, Popover } from "antd";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { CustomerInvoiceStateTag } from "../../Components/CustomerInvoiceStateTag";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import Stack from "../../Components/Stack";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import { findInvoiceSubset } from "../../Helpers/findInvoiceSubset";
import { sum } from "../../Helpers/sum";
import { CustomerInvoiceStateDropdown } from "../ViewShipmentScreenComponents/CustomerInvoiceStateDropdown";
import HorizontalStack from "../../Components/HorizontalStack";
import { DownloadCustomerStatementButton } from "../../Components/DownloadCustomerStatementButton";

interface CompanyInvoicesTabProps {
  companyData: CompanyData;
}

export function CompanyInvoicesTab(props: CompanyInvoicesTabProps) {
  const [customerInvoiceStateFilter, setCustomerInvoiceStateFilter] = useState<
    CustomerInvoiceState | undefined
  >(undefined);
  const [priceFilter, setPriceFilter] = useState<number | null | undefined>();
  const [attemptToReconcileFilter, setAttemptToReconcileFilter] = useState<
    number | null | undefined
  >();
  const [selectedInvoices, setSelectedInvoices] = useState<CustomerInvoice[]>(
    [],
  );

  function doStateFilter(ci: CustomerInvoice): boolean {
    if (customerInvoiceStateFilter === undefined) {
      return true;
    } else {
      return ci.customerInvoiceState === customerInvoiceStateFilter;
    }
  }

  function doPriceFilter(ci: CustomerInvoice): boolean {
    if (priceFilter === undefined || priceFilter === null) {
      return true;
    } else {
      return ci.amount === priceFilter;
    }
  }

  function findInvoicesForReconciliation(): CustomerInvoice[] | undefined {
    if (
      attemptToReconcileFilter === undefined ||
      attemptToReconcileFilter === null
    ) {
      return undefined;
    }

    const possibleInvoices = props.companyData.customerInvoices.filter(
      (ci) =>
        ci.customerInvoiceState === CustomerInvoiceState.Issued ||
        ci.customerInvoiceState === CustomerInvoiceState.SettlementPending,
    );

    const subset = findInvoiceSubset(
      possibleInvoices,
      attemptToReconcileFilter,
    );

    return subset;
  }

  const reconciliationReport = findInvoicesForReconciliation();

  function attemptToReconcileLabel() {
    if (
      attemptToReconcileFilter === undefined ||
      attemptToReconcileFilter === null
    ) {
      return `Attempt To Reconcile`;
    } else {
      if (reconciliationReport === undefined) {
        return `Attempt To Reconcile (❌ Unable to find set)`;
      } else {
        return `Attempt To Reconcile (✅ Found ${reconciliationReport.length} invoices)`;
      }
    }
  }

  function doAttemptToReconcileFilter(ci: CustomerInvoice): boolean {
    if (reconciliationReport === undefined) {
      return true;
    } else {
      return (
        reconciliationReport.find(
          (ci2) => ci.customerInvoiceId === ci2.customerInvoiceId,
        ) !== undefined
      );
    }
  }

  const filteredInvoices = props.companyData.customerInvoices
    .filter(doStateFilter)
    .filter(doPriceFilter)
    .filter(doAttemptToReconcileFilter);

  const columns: DataTableColumn<CustomerInvoice>[] = [
    {
      title: "Identifier",
      render: (o) => <div>{o.invoiceIdentifier}</div>,
    },
    {
      title: "State",
      render: (o) => <CustomerInvoiceStateTag customerInvoice={o} />,
    },
    {
      title: "Disputed",
      render: (o) => (o.disputed ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: (
        <Stack align="left">
          <div>Amount</div>
          <div style={{ color: Colors.LightText, fontSize: "8px" }}>
            Total ${sum(filteredInvoices, (ci) => ci.amount!).toFixed(2)}
          </div>
        </Stack>
      ),
      render: (o) => (
        <div>
          {o.amount} {o.currency}
        </div>
      ),
      sorter: (a, b) => {
        return a.amount! - b.amount!;
      },
    },
    {
      title: "Created",
      render: (o) => <CreatedAt timestamp={o.createdAt} days></CreatedAt>,
    },
    {
      title: "Due",
      render: (o) => <CreatedAt timestamp={o.dueDate} days></CreatedAt>,
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.shipmentId!} />
        </ButtonRow>
      ),
    },
  ];
  return (
    <>
      <HorizontalStack align="spread">
        <ButtonRow>
          <Form.Item label="State">
            <CustomerInvoiceStateDropdown
              value={customerInvoiceStateFilter}
              setValue={setCustomerInvoiceStateFilter}
            />
          </Form.Item>
          <Form.Item label="Price">
            <InputNumber value={priceFilter} onChange={setPriceFilter} />
          </Form.Item>
          <Form.Item label={attemptToReconcileLabel()}>
            <InputNumber
              value={attemptToReconcileFilter}
              onChange={setAttemptToReconcileFilter}
            />
          </Form.Item>
          {selectedInvoices.length > 0 && (
            <Form.Item label="Selected Total">
              <div>${sum(selectedInvoices, (ci) => ci.amount!).toFixed(2)}</div>
            </Form.Item>
          )}
        </ButtonRow>
        <ButtonRow>
          <Popover
            content={
              <Stack align="left" style={{ marginLeft: "-16px" }}>
                <DownloadCustomerStatementButton
                  customerInvoices={props.companyData.customerInvoices}
                  companyData={props.companyData}
                />
              </Stack>
            }
            title="Available Reports"
            trigger="hover"
            placement="left"
          >
            <span
              style={{
                color: Colors.Blue,
                cursor: "pointer",
                fontSize: "11px",
                fontWeight: 600,
              }}
            >
              Available Reports
            </span>
          </Popover>
        </ButtonRow>
      </HorizontalStack>

      <DataTable
        pagination={false}
        columns={columns}
        data={filteredInvoices}
        rowKey={function (row) {
          return row.customerInvoiceId!;
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedInvoices.map((a) => a.customerInvoiceId!),
          onChange: (
            _selectedRowKeys: React.Key[],
            _selectedRows: CustomerInvoice[],
          ) => {
            setSelectedInvoices(_selectedRows);
          },
        }}
      />
    </>
  );
}
